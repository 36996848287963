import React from "react"
import "./style.css"

const Joomla = () => {
  return (
    <section className="joomla">
      <div className="joomla-main">
        <p className="joomla-plugin">Powerful security plugin</p>
        <h1 className="joomla-title">WebTotem for Joomla</h1>
        <h2 className="joomla-description">
          The plugin offers a reliable and comprehensive solution for
          vulnerability detection, security monitoring, and protection against
          cyber threats.
        </h2>
        <div className="joomla-links">
          <a href="#download" className="joomla-link">
            Download
          </a>
        </div>
      </div>
    </section>
  )
}

export default Joomla
