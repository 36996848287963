import React from "react"
import "./styles.css"

import Scanning from "../../images/jooomla-scanning.png"
import AuditLogs from "../../images/joomla-audit-logs.png"
import Map from "../../images/joomla-map.png"
import SSL from "../../images/joomla-ssl.png"
import Analytics from "../../images/joomla-analytics.png"

export const HowItWorks = () => {
  return (
    <div className="container">
      <section className="joomla-how-it-works">
        <header className="joomla-how-it-works__header">
          <h2 className="joomla-how-it-works__title">How it works?</h2>
          <h3 className="joomla-how-it-works__sub-title">
            Powerful security plugin
          </h3>
        </header>
        <div className="joomla-how-it-works__content">
          <div className="joomla-how-it-works__card">
            <img src={AuditLogs} />
            <footer className="joomla-how-it-works__card-footer">
              <h4 className="joomla-how-it-works__card-title">
                Vulnerability Detection
              </h4>
              <p className="joomla-how-it-works__card-text">
                The WebTotem plugin conducts a comprehensive analysis of your
                website using intelligent algorithms to identify potential
                vulnerabilities.{" "}
              </p>
            </footer>
          </div>
          <div className="joomla-how-it-works__card joomla-how-it-works__card--dark">
            <h4 className="joomla-how-it-works__card-title">Scanning</h4>
            <img src={Scanning} />
            <footer className="joomla-how-it-works__card-footer">
              <h4 className="joomla-how-it-works__card-title">
                Security Monitoring
              </h4>
              <p className="joomla-how-it-works__card-text joomla-how-it-works__card-text--dark">
                It performs regular checks for site availability, analyzes error
                logs, and detects malicious code or suspicious activities.
              </p>
            </footer>
          </div>
          <div className="joomla-how-it-works__card joomla-how-it-works__card--dark">
            <h4 className="joomla-how-it-works__card-title">Firewall</h4>
            <div className="joomla-how-it-works__card-text joomla-how-it-works__card-text--dark">
              The plugin is equipped with intelligent algorithms that analyze
              and block potentially dangerous IP addresses and malicious
              traffic.
            </div>
            <img src={Map} alt="" />
          </div>
          <div className="joomla-how-it-works__card">
            <h4 className="joomla-how-it-works__card-title">
              SSL Certificate Monitoring
            </h4>
            <div className="joomla-how-it-works__card-content">
              {" "}
              <p className="joomla-how-it-works__card-text">
                WebTotem checks your SSL certificates to ensure their proper
                configuration and timely updates.
              </p>
              <p className="joomla-how-it-works__card-text">
                The plugin alerts you to any certificate issues, such as expired
                dates or incorrect configurations, to ensure a secure connection
                to your website.
              </p>
            </div>
            <img src={SSL} />
          </div>

          <div className="joomla-how-it-works__card joomla-how-it-works__card--dark">
            <h4 className="joomla-how-it-works__card-title">
              Reporting and Analytics
            </h4>
            <p className="joomla-how-it-works__card-text">
              Plugin provides detailed reports on the security status of your
              website.{" "}
            </p>
            <p className="joomla-how-it-works__card-text">
              It gives you insights into vulnerabilities, security incidents,
              and overall website health, allowing you to take proactive
              measures to enhance your site's security.
            </p>
            <img src={Analytics} />
          </div>
        </div>
      </section>
    </div>
  )
}
