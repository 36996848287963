import React from "react"
import "./styles.css"
import Ver3 from "../../images/joomla-3.png"
import Ver4 from "../../images/joomla-4.png"

const Download = () => {
  return (
    <section id="download" className="joomla-download">
      <div className="joomla-download__top"></div>
      <div className="joomla-download__main">
        <div className="joomla-download__title">
          <h2>Need protection? </h2>
          <h2>Install our plugin now</h2>
        </div>
        <div className="joomla-download__content container">
          <div className="joomla-download__item">
            <div>
              <p>For Joomla 3</p>
              <h3>Install the plugin on your site</h3>
              <div>
                <a
                  className="wt-button wt-button--primary"
                  href="/joomla/packages/wtotem_j3_latest.zip"
                >
                  Download
                </a>
                <span>584KB .zip</span>
              </div>
            </div>
            <img src={Ver3} />
          </div>
          <div className="joomla-download__item">
            <div>
              <p>For Joomla 4 & 5</p>
              <h3>Install the plugin on your site</h3>
              <div>
                <a
                  className="wt-button wt-button--primary"
                  href="/joomla/packages/wtotem_j4_j5_latest.zip"
                >
                  Download
                </a>
                <span>588KB .zip</span>
              </div>
            </div>
            <img src={Ver4} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Download
