import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Joomla from "../../sections/joomla"
import Download from "../../sections/download"
import ScoringSection from "../../sections/scoring"
import Footer from "../../sections/footer"
import "./styles.css"
import { HowItWorks } from "../../sections/joomla-how-it-works"

const JoomlaPage = () => {
  return (
    <Layout>
      <SEO
        title="WebTotem plugin for Joomla"
        description="Download WebTotem plugin for Joomla"
      />
      <div className="joomla-hero">
        <Header theme="dark" />
        <Joomla />
      </div>
      <HowItWorks />
      <Download />
      <ScoringSection />
      <Footer />
    </Layout>
  )
}

export default JoomlaPage
